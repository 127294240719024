<template>
	<section class="classManagementPage">
		<g-breadcrumb bread="商品管理" />
		<SearchForm>
			<el-form ref="ref_searchForm" :inline="true" :model="searchForm" label-width="80px">
				<el-form-item>
					<!-- <el-input v-model.trim="searchForm.orgName" placeholder="请输入商品ID" /> -->
					<!-- <el-cascader
            v-model="searchForm.kindergartenIds"
            style="width: 420px;"
            :options="orgGardenTree"
            placeholder="请选择归属"
            clearable
            filterable
            collapse-tags
            :props="{ multiple: true, checkStrictly: false, emitPath: false, label: 'orgName', value: 'orgId' }"
          /> -->
				</el-form-item>
				<el-form-item>
					<el-input v-model.trim="searchForm.goodsName" placeholder="请输入商品名称" />
					<!-- <el-select v-model="searchForm.gradeCode" placeholder="请选择商品信息" clearable>
            <el-option
              v-for="item in gradeOptions"
              :key="item.dicCode"
              :label="item.dicName"
              :value="item.dicCode"
            />
          </el-select> -->
				</el-form-item>
				<el-form-item>
					<el-button type="primary" @click="fetchData">搜 索</el-button>
				</el-form-item>
			</el-form>
		</SearchForm>
		<TableContainer title="商品列表">
			<el-table :data="tableData" stripe style="width: 100%" border :header-cell-style="tabHeader" :cell-style="{ textAlign: 'center' }">
				<!-- <el-table-column prop="goodsNo" label="商品id" min-width="100" /> -->
				<el-table-column prop="goodsNo" label="商品编号" min-width="100" />
				<el-table-column prop="specNo" label="商品编码" min-width="100" />
				<el-table-column prop="goodsName" label="商品名称" min-width="100" />
				<el-table-column prop="className" label="商品分类" min-width="100" />
				<el-table-column prop="shortName" label="简称" min-width="100" />
				<el-table-column prop="specName" label="规格名称" min-width="100" />
				<el-table-column prop="brandName" label="品牌名称" min-width="100" />
				<el-table-column prop="specCode" label="规格码" min-width="100" />
				<el-table-column prop="barcode" label="主条码" min-width="100" />
				<el-table-column prop="price" label="零售价" min-width="100" />
				<!-- <el-table-column width="120" label="操作">
					<template slot-scope="scope">
						<el-button type="text" class="textOrange" @click="deleteClass(scope.row)">移除</el-button>
					</template>
				</el-table-column> -->
			</el-table>
			<GPagination v-show="total > 0" ref="ref_GPagination" :total="total" :page.sync="searchForm.page"
				:size.sync="searchForm.size" @pagination="fetchData" />
		</TableContainer>
	</section>

</template>

<script>
	import {
		mapState
	} from 'vuex'
	import {
		findGoods
	} from '@api/classManagement/api_classManagement'
	import {
		getDictoryInfoByCode
	} from '@api/api_common'
	export default {
		data() {
			return {
				currentRow: {},
				gradeOptions: [],
				tableData: [],
				total: 0,
				searchForm: {
					goodsName: '',
					// kindergartenIds: [],
					// gradeCode: '',
					page: 1,
					size: 20
				}
			}
		},
		computed: {
			...mapState('commonModule', ['orgGardenTree'])
		},
		created() {
			getDictoryInfoByCode('GRADE_TYPE').then(res => {
				this.gradeOptions = res
			})
			// this.getOptions()
			this.fetchData()
		},

		methods: {
			// getOptions() {
			// 	// 查询组织机构树-到片区；2到片区，3到园所
			// 	this.$store.dispatch('commonModule/getOrgTree', 3)
			// },
			tabHeader() {
				return 'backgroundColor:rgba(88, 227, 232, 0.12);color:#000;font-weight:bold;font-size:15px;text-align: center;'
			},
			fetchData() {
				findGoods(this.searchForm).then(res => {
				  console.log(res,111111)
				  this.tableData = res.records
				  this.total = res.total
				})
			},
			deleteClass(row) {
				this.$confirm('是否确认删除?', '提示', {
					type: 'warning',
					closeOnClickModal: false
				}).then(() => {
					if (parseInt(row.studentCount) > 0) {
						this.$message.error('该班级下有学生，不可移除！')
						return false
					}
					deleteClassInfo({
						classId: row.classId
					}).then(res => {
						this.$message.success('移除成功！')
						if (this.total % this.searchForm.size === 1) {
							this.searchForm.page = this.searchForm.page - 1 === 0 ? 1 : this.searchForm
								.page - 1
						}
						this.$refs.ref_GPagination.resetDeletPage()
					})
				})
			}
		}
	}
</script>

<style lang="scss"></style>
