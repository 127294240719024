import request from '@utils/fetch'

// 班级列表接口
export function findGoods(data) {
  return request({
    url: '/trade/findGoods',
    method: 'post',
    data
  })
}

// 移除班级接口
// export function deleteClassInfo(data) {
//   return request({
//     url: '/classInfoManage/deleteClassInfo',
//     method: 'delete',
//     params: data
//   })
// }
